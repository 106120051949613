<template>
    <Content :search="false">
        <template v-slot:topbar></template>
        <template v-slot:content>
            <Header/>

            <div class="grid grid-cols-12 w-full gap-5">
                <div class="col-span-12 lg:col-span-8 rounded-lg flex flex-col gap-5">
                    <template v-for="item in this.user.role.dashboard_components" :key="item.id">
                        <template v-if="item.is_large">
                            <component
                                :is="getComponent(item.name)"
                                :component="item"
                            />
                        </template>
                    </template>
                </div>

                <div class="col-span-12 lg:col-span-4 rounded-lg space-y-5">
                    <template v-for="item in this.user.role.dashboard_components" :key="item.id">
                        <template v-if="!item.is_large">
                            <component
                                :is="getComponent(item.name)"
                                :component="item"
                            />
                        </template>
                    </template>
                </div>
            </div>
        </template>
    </Content>
</template>

<script>
import {mapGetters} from "vuex"
import {defineAsyncComponent} from 'vue'

const Header = defineAsyncComponent(() =>
    import('@/components/Home/Header'))
const NewsWidget = defineAsyncComponent(() =>
    import('@/components/Home/News/NewsWidget'))
const CouriersWidget = defineAsyncComponent(() =>
    import('@/components/Home/Couriers/CouriersWidget'))
const LowStockWidget = defineAsyncComponent(() =>
    import('@/components/Home/LowStock/LowStockWidget'))
const UserProductionsCountWidget = defineAsyncComponent(() =>
    import('@/components/Home/UserProductionsCount/UserProductionsCountWidget'))
const CategoriesWidget = defineAsyncComponent(() =>
    import('@/components/Home/Categories/CategoriesWidget'))
const CustomerSalesDataWidget = defineAsyncComponent(() =>
    import('@/components/Home/CustomerSalesData/CustomerSalesDataWidget'))
const CelebrationsWidget = defineAsyncComponent(() =>
    import('@/components/Home/Celebrations/CelebrationsWidget'))

export default {
    name: "Home",
    components: {
        Header,
    },
    computed: {
        ...mapGetters({
            user: "user",
        })
    },
    methods: {
        getComponent(name) {
            const components = {
                news: NewsWidget,
                low_stock: LowStockWidget,
                couriers: CouriersWidget,
                user_productions_count: UserProductionsCountWidget,
                categories: CategoriesWidget,
                customers: CustomerSalesDataWidget,
                celebrations: CelebrationsWidget,
            };

            return components[name];
        },
    }
};
</script>

<style>
</style>